@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";
@import "/src/main.css";
@charset "UTF-8";

.report-container {
  height: 99vh;
  width: 100%;
  max-width: 1200px;
  padding-top: 1rem;
}

.invalid-input {
  border: solid 1px #ac1b1b;
}

.invalid-input-message {
  color: #ac1b1b;
}

/* ng-bootstrap datepicker */
.ngb-dp-weekday {
  color: black !important;
  font-style: normal !important;
}

@media screen and (max-width: 480px) {
  .ngb-dp-months {
    flex-direction: column;
  }
}
.shepherd-element.shepherd-has-title[data-popper-placement^="bottom"]
  > .shepherd-arrow:before {
  background-color: #fff;
}

/* ngb popover */
@media (max-width: 767px) {
  .property-name-popover {
    width: 13rem;
  }

  .property-url-popover {
    width: 12rem;
  }

  .property-goal-popover {
    width: 11rem;
  }
  .property-track-popover {
    width: 15rem;
  }
  .property-track-financial-popover {
    width: 10rem;
  }
  .userprofile-property-popover {
    width: 15.5rem;
  }

  .userprofile-url-popover {
    width: 10.5rem;
  }
  .userprofile-brand-popover {
    width: 10.5rem;
  }
  .userprofile-reviewer-popover {
    width: 10.5rem;
  }
  .userprofile-revieweremail-popover {
    width: 10.5rem;
  }
  .org-totalFTE-popover {
    width: 10.5rem;
  }
  .org-totalFemaleFTE-popover {
    width: 10.5rem;
  }
  .org-customerservicerole-popover {
    width: 10.5rem;
  }
}

@media (max-width: 390px) {
  .property-goal-popover {
    width: 10rem;
  }
}

@media (max-width: 370px) and (min-width: 330px) {
  .property-goal-popover {
    width: 8.5rem;
  }
}

.div-page-centered {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-danger {
  color: #ac1b1b !important;
}

.required-field {
  padding-left: 0.4rem;
}

.compare-btn {
  width: 11rem;
  height: 2.4rem;
  padding-left: 23px;
  border-radius: 5px;
}

.compare-btn-icon {
  height: 1.5rem;
}

.sign-out-menu {
  padding-top: 2rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.missing-permissions {
  font-size: small;
}
.tour-number {
  margin-right: auto;
  margin-top: 8px;
}
/*Start: Tour Popup Section*/
.shepherd-has-title .shepherd-content .shepherd-header {
  background: white;
  padding: 1em;
  border-bottom: 0.5rem;
  border-color: #d8dbe0;
  border-bottom: 1px;
  align-items: center;
  flex-shrink: 0;
  display: flex;
  border-bottom: 1px solid #d8dbe0;
}
.shepherd-cancel-icon {
  background-image: url("./assets/icons/btn-close.svg");
  width: 2.05em;
  height: 0.7em;
  padding-top: 0.25rem;
  cursor: pointer;
  background-repeat: no-repeat;
  margin-right: -36px;
}
.shepherd-cancel-icon span {
  display: none;
}
.shepherd-footer {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: flex-end;
  padding: 14px 0.75rem 0.75rem;
  border-top: 1px solid #d8dbe0;
}
.shepherd-title {
  font-size: 1.3rem;
}
/*End:Tour Popup Section*/
.form-control:focus {
  border-color: #404040;
  box-shadow: 0 0 0 0rem rgba(64, 64, 64, 1);
}
.form-check-input:focus[type="radio"] {
  border-color: #404040;
  box-shadow: 0 0 0 0rem rgba(64, 64, 64, 1);
}
.form-select:focus {
  border-color: #404040;
  box-shadow: 0 0 0 0rem rgba(64, 64, 64, 1);
}
.form-switch .form-check-input:focus {
  border-color: var(--cui-border-color);
  box-shadow: 0 0 0 0rem rgba(64, 64, 64, 1);
  --cui-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 21, 0.25%29'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:focus:checked {
  --cui-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.87%29'/%3e%3c/svg%3e");
}
.accordion-button:focus {
  border-color: #404040;
  box-shadow: 0 0 0 0rem rgba(64, 64, 64, 1);
}

@media (max-width: 575px) {
  .journal-offcanvas {
    width: 100% !important;
  }
}
.journal-delay-message {
  border-top: 1px solid #9e9e9e;
}

.page-title-icon {
  height: 20px;
}
.popover {
  background: #ededeb;
}

.popover .popover-body {
  padding: 7px 16px;
}

.popover .popover-arrow {
  display: none;
}

/* Owl Carousel */
.owl-theme .owl-dots .owl-dot span {
  background: #f9eac5;
}
.owl-theme .owl-dots .owl-dot.active span {
  background: #ff9235;
}

.owl-theme {
  max-width: min(90%, 500px);
}
/* Owl Carousel END */

.mr-auto {
  margin-right: auto;
}

.mb-0 {
  margin-bottom: 0;
}
