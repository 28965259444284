@charset "UTF-8";
/*Root*/
:root {
  --cui-primary: #e7b680;
  --cui-secondary: #d87850;
  --cui-border: #a8a8a8;
  --cui-link-color: #8f401e;
  --cui-link-hover-color: #8f401e;
  --cui-body-font-family: "Verdana", Trebuchet MS, Helvetica, sans-serif;
  --cui-success: #d1e7dd;
  --cui-danger: #f8d7da;
  --cui-sidebar-nav-group-bg: #fff;
}
@media (hover: hover), (-ms-high-contrast: none) {
  .sidebar-nav .nav-link:hover.nav-group-toggle::after {
    background-image: url('data:image/svg+xml,<svg width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.666687 0H12.6667L7.27269 5.394C7.11195 5.55469 6.89397 5.64496 6.66669 5.64496C6.4394 5.64496 6.22143 5.55469 6.06069 5.394L0.666687 0Z" fill="black"/></svg>');
  }
}
@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }
}
/*Full screen*/
.wrapper {
  width: 100%;
  will-change: auto;
  transition: padding 0.15s;
}
html:not([dir="rtl"]) .wrapper {
  padding-left: var(--cui-sidebar-occupy-start, 0);
}
*[dir="rtl"] .wrapper {
  padding-right: var(--cui-sidebar-occupy-start, 0);
}
@media (prefers-reduced-motion: reduce) {
  .wrapper {
    transition: none;
  }
}
/*Link*/
a {
  color: var(--cui-link-color);
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.dropdown-menu {
  --cui-dropdown-link-active-bg: var(--cui-link-color);
  --cui-dropdown-link-active-color: #404040;
}

.dropdown-toggle.hide-arrow:after {
  display: none;
}

/*Text*/
.col-form-label {
  color: #404040;
}
.wp-block-heading {
  font-size: 1.5rem;
  font-weight: 600;
  padding-top: 1rem;
  padding-bottom: 0.3rem;
}
.ft-b {
  color: #404040;
}
/*Spacing*/
.p-01 {
  padding: 1rem;
}
.p-02 {
  padding: 2rem;
}
.p-14 {
  padding: 1.4rem;
}
.hp-1 {
  padding: 0 1.5rem 1.5rem 1.5rem;
}
.hpr-1 {
  padding: 0.7rem 1.5rem 1.5rem 1.5rem;
}
.hpm-1 {
  padding: 1rem 1.5rem 1.5rem 1.5rem;
}
.pt-05 {
  padding-top: 0.5rem;
}
.pt-07 {
  padding-top: 0.7rem;
}
.pt-2 {
  padding-top: 2rem;
}
.pt-3 {
  padding-top: 3rem;
}
.pr-01 {
  padding-right: 0.5rem;
}
.pl-02 {
  padding-left: 0.2rem;
}
.pl-05 {
  padding-left: 0.5rem;
}
.pl-1 {
  padding-left: 1rem;
}
.pl-2 {
  padding-left: 2rem;
}
.pl-4 {
  padding-left: 4rem;
}
.pb-2 {
  padding-bottom: 2rem;
}
.pb-012 {
  padding-bottom: 1.2rem;
}
.pb-001 {
  padding-bottom: 0.1rem;
}
.pb-03 {
  padding-bottom: 0.3rem;
}
.pr-07 {
  padding-right: 0.7rem;
}
.pr-1 {
  padding-right: 1rem;
}
.pr-2 {
  padding-right: 2rem;
}

.margin-1 {
  margin: 1rem;
}
.margin-05 {
  margin: 0.5rem;
}
.mr-1 {
  margin-right: 1rem;
}
.mr-2 {
  margin-right: 2rem;
}
.mb-01 {
  margin-bottom: 1rem;
}
.mb-4 {
  margin-bottom: 4rem;
}
.mb-5 {
  margin-bottom: 5rem;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.display-inline-flex {
  display: inline-flex !important;
}
/*Borders*/
.border-bottom {
  border-bottom: 1px solid #a8a8a8;
}
.border-top {
  border-top: 1px solid #a8a8a8;
}
/*Pop Up*/

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--cui-modal-padding) - var(--cui-modal-footer-gap) * 0.5);
  border-top: 1px solid #9e9e9e;
}
/* Header */
.header {
  --cui-header-padding-y: 0.1rem !important;
}
.header {
  background-color: #fff;
  display: none;
}
@media only screen and (max-width: 768px) {
  .header {
    display: block;
  }
  .spinner-p {
    padding-top: 1.2rem;
  }
}
.sidebar-brand {
  background-color: #fff;
}

.bg-lightblue {
  background-color: #0194b2;
}
/* Body */
.body {
  background-color: #fff;
}
/*Sidebar*/
.sidebar:not(.sidebar-end) ~ * {
  --cui-sidebar-occupy-start: 15rem;
}
.sidebar {
  background-color: #ededeb;
  border-right: 0xp !important;
  width: 15rem;
  --cui-sidebar-width: 15rem;
}

.sidebar-nav .nav-link {
  color: #404040;
  cursor: pointer;
  padding: var(--cui-sidebar-nav-link-padding-x);
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}
.sidebar-nav .nav-group.show .nav-group-toggle {
  color: #404040;
}
.sidebar-nav .nav-group-toggle::after {
  background-image: url('data:image/svg+xml,<svg width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.666687 0H12.6667L7.27269 5.394C7.11195 5.55469 6.89397 5.64496 6.66669 5.64496C6.4394 5.64496 6.22143 5.55469 6.06069 5.394L0.666687 0Z" fill="black"/></svg>');
  margin-left: 0.5rem;
}
.sidebar-nav .nav-group {
  background-color: #ededeb;
}
.sidebar-nav .nav-group.show {
  background: #ededeb;
}
.nav-item {
  background-color: #ededeb;
}
.selected {
  font-weight: 600;
  color: #404040 !important;
}
.sidebar-nav .nav-group-items .nav-link {
  padding-left: 1rem;
}

.sidebar-nav .nav-group .nav-group-items {
  padding-left: 0.5rem;
}

@media (hover: hover) {
  .sidebar-nav .nav-link:hover {
    color: #404040;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer !important;
  }
}

.col-form-label {
  font-weight: 600;
}
.card-title {
  font-weight: 600;
}
/*Right- Drawer*/
.offcanvas,
.offcanvas-xxl,
.offcanvas-xl,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm {
  --cui-offcanvas-width: 555px !important;
}
/*Accordions*/
.accordion {
  --cui-accordion-color: #404040;
  --cui-accordion-bg: #fff;
  --cui-accordion-border-color: none;
  --cui-accordion-border-width: 1px;
  --cui-accordion-btn-color: #f0f0f0;
  --cui-accordion-btn-color: #404040;
  --cui-accordion-btn-focus-border-color: #a3a3a3;
  --cui-accordion-active-color: #404040;
  --cui-accordion-active-bg: #ebe9fb;
}
.accordion-button:not(.collapsed) {
  color: #404040;
  background-color: #f0f0f0;
  border: 1px solid #a3a3a3;
  border-radius: 0.5rem;
  box-shadow: inset 0 calc(-1 * var(--cui-accordion-border-width)) 0
    var(--cui-accordion-border-color);
}
.accordion-button::after {
  color: #ff5722;
}
.accordion-button {
  font-size: 1rem;
  font-weight: 600;
  color: #404040;
  padding: 0.7rem;
  background-color: #f0f0f0;
  border: 1px solid #a3a3a3;
  border-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
/*Modal Pop-up*/
.modal-nobackdrop {
  background-color: transparent !important;
  z-index: 1050;
}
/*Buttons*/
.btn-primary {
  color: #404040;
  background-color: #fff;
  font-weight: bold;
  border: 5px solid var(--cui-primary);
  --cui-btn-bg: #fff;
  --cui-btn-border-color: var(--cui-primary);
  --cui-btn-active-bg: var(--cui-secondary);
  --cui-btn-active-border-color: var(--cui-primary);
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-bg: #b3b3b3;
  --cui-btn-disabled-border-color: #9e9e9e;
  --cui-btn-border-radius: 0px;
  --cui-btn-hover-color: rgba(64, 64, 64, 100);
  --cui-btn-hover-bg: #fff;
  --cui-btn-hover-border-color: #d87850;
  --cui-btn-focus-shadow-rgb: 75, 58, 223;
  --cui-btn-active-color: rgba(255, 255, 255, 100);
}
.btn-primary:hover {
  color: #404040 !important;
  --cui-btn-hover-border-color: var(--cui-secondary);
  background-color: #fff !important;
  text-decoration: none;
}
.btn-secondary {
  color: #404040;
  background-color: #fff;
  border: 5px solid #fff;
  font-weight: bold;
  --cui-btn-border-radius: 0px;
}
.btn-secondary:hover {
  color: #404040 !important;
  background-color: #fff !important;
  border: 5px solid #b5b5ab !important;
  text-decoration: none;
}
.btn-icon {
  background-color: #fff;
  --cui-btn-border-color: #9e9e9e;
  --cui-btn-focus-shadow-rgb: 75, 58, 223;
  cursor: pointer;
}
.btn-icon-full {
  background-color: #fff;
  border: none;
  cursor: pointer;
  padding: 0;
}
.btn-icon-full i {
  font-size: 2.2rem;
  color: #ff9235;
  cursor: pointer;
}
.btn-info {
  background-color: #fff;
  --cui-btn-border-color: #9e9e9e;
  --cui-btn-focus-shadow-rgb: 75, 58, 223;
  cursor: default;
}
.search-icon {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--cui-input-group-addon-color, var(--cui-body-color));
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border-right: none !important;
  border-top: 1px solid var(--cui-border-color);
  border-bottom: 1px solid var(--cui-border-color);
  border-left: 1px solid var(--cui-border-color);
  border-radius: var(--cui-border-radius);
}
.btn-outline-primary {
  --cui-btn-font-weight: 550;
  --cui-btn-color: var(--cui-primary);
  --cui-btn-border-color: var(--cui-primary);
  --cui-btn-hover-bg: var(--cui-primary);
  --cui-btn-hover-border-color: var(--cui-primary);
  --cui-btn-focus-shadow-rgb: 50, 31, 219;
  --cui-btn-active-bg: var(--cui-primary);
  --cui-btn-active-border-color: var(--cui-primary);
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: #b3b3b3;
  --cui-btn-disabled-bg: transparent;
  --cui-gradient: none;
}
.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  color: #404040;
}

.form-check-input:checked {
  background-color: var(--cui-form-check-input-checked-bg-color, #6c6c6c);
  border-color: var(--cui-form-check-input-checked-border-color, #6c6c6c);
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  color: #6c6c6c;
  pointer-events: none;
  background-color: #cbcbcb;
  border-color: var(--cui-primary);
}
.btn-off {
  background-image: url("./assets/icons/btn-close.svg");
  width: 2.05em;
  height: 1.7em;
  padding-top: 0.25rem;
  cursor: pointer;
  background-repeat: no-repeat;
}
/*Image*/
.h-45 {
  height: 5rem;
}
/*Image*/
.h-25 {
  height: 2rem !important;
}
/*Pagination*/
.pagination {
  --cui-pagination-color: var(--cui-primary);
  --cui-pagination-focus-color: var(--cui-black);
  --cui-pagination-active-bg: var(--cui-primary);
  --cui-pagination-active-border-color: var(--cui-primary);
  --cui-pagination-hover-color: var(--cui-black);
}
.page-link.active,
.active > .page-link {
  color: var(--cui-black);
  font-weight: 600;
}
/*Footer*/
.footer {
  display: flow;
}
.actionbar {
  padding-top: 1.5rem;
  border-top: 1px solid #a3a3a3;
}
/*Accessibility*/
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

/* Vide0 */
.vdo-embed-wrapper {
  height: 0;
  margin-bottom: 4rem;
  padding-top: 1.5rem;
  position: relative;
  width: 100%;
}
/*List*/
li {
  color: var(--cui-primary);
}
li span {
  color: black;
}

.wide-panel {
  width: 35rem !important;
}

/* pagination */
.pagination {
  --cui-pagination-color: var(--cui-primary);
  --cui-pagination-focus-color: var(--cui-black);
  --cui-pagination-active-bg: var(--cui-primary);
  --cui-pagination-active-border-color: var(--cui-primary);
  --cui-pagination-hover-color: var(--cui-black);
}
.page-link.active,
.active > .page-link {
  color: var(--cui-black);
  font-weight: 600;
}

.wrap-text {
  text-wrap: wrap !important;
}

.btn-sea-blue {
  background-color: #008196;
  color: #fff;
  --cui-btn-border-radius: 0px;
}

.btn-sea-blue:hover {
  background-color: #017289;
}

.menu-link-icn {
  visibility: hidden;
  padding-top: 0.25rem;
}

.sidebar-nav .nav-link:hover .menu-link-icn {
  visibility: visible;
  opacity: 1;
}

.journal-grid {
  width: 100%;
  height: 100%;
}

.brand-upload {
  width: 8rem;
}
.brand-upload-i {
  font-size: 2rem;
  color: #ff9235;
  cursor: pointer;
}
.brand-upload-logo {
  width: 5rem;
  cursor: pointer;
}
.brand-footer {
  margin-bottom: 20px;
}
.property-component {
  width: 50rem !important;
}
.pointer {
  cursor: pointer;
}
.brands-img {
  width: auto;
  height: 100px;
}
.property-footer {
  margin-bottom: 20px;
}
.spinner-p {
  color: white;
}
.no-brands {
  color: red;
}
.no-organization {
  font-size: x-large;
  text-align: center;
}
.partner-name-grid {
  cursor: pointer;
  text-decoration: underline;
}
.partners-grid {
  width: 100%;
  height: 100%;
}
.users-grid {
  width: 100%;
  height: 100%;
}
.yt-btn {
  margin-left: 25px !important;
}

.nav-link:focus-visible {
  box-shadow: 1px 1px 3px 1px #808080;
}
.responsive-iframe {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.main-menu-link {
  font-size: 0.875rem;
}

.sub-menu-link {
  font-size: 0.875rem;
}

.m-07 {
  margin: 0.7rem;
}

.add-entry-text {
  padding: 0 1rem 1rem 1rem;
  margin-bottom: 0;
}

.organization-help-text,
.delay-help-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.grid-container-65 {
  height: 65vh;
}

.grid-container-70 {
  height: 70vh;
}

.grid-container-80 {
  height: 80vh;
}

.height-70 {
  height: 70rem !important;
}

.height-80 {
  height: 80rem !important;
}

.multiselect-dropdown .dropdown-btn {
  padding: 8px 6px 0px 8px !important;
  min-height: 43px;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
  height: 43px !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
  border: none !important;
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");

  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px 12px;
  display: block;
  width: 16px !important;
  height: 12px !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  margin-top: 0 !important;
}

.multiselect-item-checkbox input[type="checkbox"] + div:before {
  color: var(--cui-primary) !important;
  border-color: var(--cui-primary) !important;
}

.multiselect-item-checkbox input[type="checkbox"]:checked + div:before {
  background: var(--cui-primary) !important;
}

.multiselect-dropdown .dropdown-btn .selected-item:hover {
  box-shadow: none !important;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  border-color: var(--cui-primary) !important;
  background: var(--cui-primary) !important;
  border-radius: 6px !important;
  max-width: initial !important;
  margin-bottom: 6px;
}

.round-image {
  width: 60px; /* Adjust the size as needed */
  height: 60px; /* Make the height equal to the width */
  object-fit: cover; /* Ensures the image covers the entire area */
  border-radius: 50%; /* Makes the image round */
  overflow: hidden; /* Prevents overflow */
}

.accordion-button,
.accordion-button:not(.collapsed) {
  background-color: white;
  border: none;
}

.form-check-input:checked {
  background-color: #ff9235;
  border: none;
}
